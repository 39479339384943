<template>
    <div class="button_cont">
        <a class="example_f" :href=link :style="{background:bgc, color:txtc}" rel="nofollow">
            <span>{{textcontent}}</span>

        </a>
    </div>
</template>

<script>
export default {
    name:  "Mybutton",
    props:{
      textcontent:String,
      bgc:String,
      txtc:String,
      link:String
    }
}
</script>
<style scoped>
.example_f {
   border-radius: 8px;
   
   border: none;
   color: #FFFFFF;
   text-align: center;
   
   font-size: 1.7rem;
   padding: 12px 14px;
   width: 200px;
   transition: all 0.4s;
   cursor: pointer;
   
 }
 .example_f span {
   cursor: pointer;
   display: inline-block;
   position: relative;
   transition: 0.4s;
 }
 .example_f span:after {
   content: '\00bb';
   position: absolute;
   opacity: 0;
   top: 0;
   right: -20px;
   transition: 0.5s;
 }
 .example_f:hover span {
   padding-right: 25px;
 }
 .example_f:hover span:after {
   opacity: 1;
   right: 0;
 }
 .button_cont {
    margin-top: 20px;
    margin-bottom: 20px;
}
.example_f:focus{
  background-color: lightskyblue !important;
  border: 1px solid black;
}
@media screen  and (max-width: 1400px){
  .example_f{
    font-size: 1.4rem;
  }
}

@media screen  and (max-width: 950px){
  .example_f{
    font-size: 1.3rem;
  }
}

@media screen  and (max-width: 600px){
  .example_f{
    font-size: 1rem;
  }
}
</style>