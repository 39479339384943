<template>
    <form>
        <div>

        <h1>Start optimizing your chemical reactions now</h1>
        <h4>Only clients with a verified license would be allowed to login to the platform </h4>

        <br>
        <div class ="accessbtn">

            <mybutton  bgc="green" txtc="white" textcontent="Access The Platform" link="/dashboard"></mybutton>
        </div>
        </div>
    <div class="subform">
        <h3>Not a client yet ?</h3>
        <Mybutton bgc="blue" txtc="white" textcontent="Become Now" @click="showContactForm"/>
    </div>
    </form>
    
</template>
<script>
import Mybutton from "./Mybutton.vue"
export default {
    name:"JoinForm",
    components:{
        Mybutton
    },
    methods:{
        showContactForm(){
            this.$emit('showContact', true)
        }
    }
}
</script>

<style scoped>
form{
    margin-inline-start: 10% ;
    margin-inline-end: 10%;
    height: fit-content;
    border-radius: 20px;
    padding-block-end: 3rem;
    display: flex;
    flex-direction: column;
    
}
.subform{
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: auto;
}
h3{
    padding-top: 1.5rem;
    margin-right: 2rem;
}
h1 ,h3,h4{
    color: white;
}
h4{
    font-weight: 400;
    text-align: center;
}
h1{
    text-align: center;
    font-size: 60px;
}
.accessbtn{
    width: fit-content;
    margin: auto;
}
@media screen and (max-width:1250px){
    h1{
        font-size: 50px;
    }
    
}
@media screen and (max-width:950px){
    h1{
        font-size: 50px;
    }
    
}
@media screen and (max-width:500px){
    h1{
        font-size: 30px;
    }
    h4{
        font-size: 15px;
    }
    h3{
        font-size: 18px;
    }
    
}
</style>
