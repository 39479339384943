<template>


  <div class=" container-xxlg">
    <div class="navbarfrontpage">

      <div class="nav-bar">
        <NavBar/>

      </div>
      <div class="frontpage">
        <div class="sidetext">

          <h1>Driving change in the chemical industry</h1>
          <h4>SuntheticsML is a machine-learning platform that accelerates innovation and sustainability in the chemical
            industry. Use up to 5 times less resources and time to optimize chemical processes and formulations
          </h4>
          <Mybutton class="discover" bgc="green" txtc="white" textcontent="Discover More" link="#carouselExampleDark"/>
        </div>
        <contactform class="contactform" v-if="noclient"></contactform>
        <JoinForm v-if="client" class="joinform" @showContact="contact"/>
      </div>
    </div>
    <div id="carouselExampleDark" class="carousel carousel-fade slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner items">
        <div class="carousel-item active item1" data-bs-interval="5000">
          <div class="item-content">
            <div class="text m">
              <h1>What we do</h1>
              <h4>We offer a machine-learning tool that is capable of leveraging
                small datasets to generate big insights.</h4>
            </div>
            <mybutton txtc="white" textcontent="See More" bgc="green" link="#whatwedo" to="google.com"></mybutton>

          </div>
        </div>
        <div class="carousel-item item2" data-bs-interval="5000">
          <div class="item-content">
            <div class="text">
              <h1>Why AI</h1>
              <h4>Chemical reactions are affected by many different variables.
                Even with significant expertise, it takes hundreds of experiments
                to optimize a reaction.</h4>
            </div>
            <mybutton txtc="white" textcontent="See More" bgc="green" link="#whyai"></mybutton>


          </div>
        </div>
        <div class="carousel-item item3">
          <div class="item-content">
            <div class="text">
              <h1>What we stand for</h1>
              <h4>We are part of a new generation of chemical engineers that
                have a responsibility to the environment and to our society.</h4>
            </div>
            <mybutton txtc="white" textcontent="See More" bgc="green" link="#whatwestandfor"></mybutton>

          </div>
        </div>
      </div>
      <button class="carousel-control-prev prev" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next next" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <!-- Three columns of text below the carousel -->


    <!-- START THE FEATURETTES -->

    <hr class="featurette-divider">

    <div class="row featurette feature">
      <div class="col-md-7 featuretext" id="whatwedo">
        <h2 class="featurette-heading">WHAT WE DO <span class="text-muted"></span></h2>
        <p class="lead">We offer a machine-learning tool that is capable of leveraging
          small datasets to generate big insights. Sunthetics' platform enables
          process chemists to innovate and pinpoint viable processes using only
          a few experiments, accelerating the path to market for innovation by 75%.</p>
        <br>
        <!-- <mybutton bgc="green" txtc="white" textcontent="More Details"></mybutton> -->
      </div>
      <div class="col-md-5 featureimage featureimage1">

      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette feature">
      <div class="col-md-7 order-md-2 featuretext" id="whyai">
        <h2 class="featurette-heading">WHY MACHINE LEARNING <span class="text-muted"></span></h2>
        <p class="lead">Chemical reactions are affected by many different variables.
          Even with significant expertise, it takes hundreds of experiments
          to optimize a reaction. The use of machine-learning enables us to
          reduce our resource use and accelerate development time by an
          order of magnitude. Innovation doesn't need to take years.</p>
        <br>
        <!-- <mybutton bgc="green" txtc="white" textcontent="More Details"></mybutton> -->

      </div>
      <div class="col-md-5 order-md-1 featureimage featureimage2">


      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette feature">
      <div class="col-md-7 featuretext" id="whatwestandfor">
        <h2 class="featurette-heading">WHAT WE STAND FOR <span class="text-muted"></span></h2>
        <p class="lead">We are part of a new generation of chemical engineers that
          have a responsibility to the environment and to our society.
          We incorporate sustainability in our solution as an asset,
          and not a disadvantage. We focus on both sustainability and cost.</p>
        <br>
        <!-- <mybutton bgc="green" txtc="white" textcontent="More Details"></mybutton> -->

      </div>
      <div class="col-md-5 featureimage featureimage3">


      </div>
    </div>

    <hr class="featurette-divider">

    <!-- /END THE FEATURETTES -->


  </div>
  <Footer colorr="black" />

</template>

<script>
import Contactform from "../components/Contactform.vue"
import JoinForm from "../components/JoinForm.vue"
import NavBar from "../components/Navbar.vue"
import Mybutton from "../components/Mybutton.vue"
import Footer from "../components/footer.vue"

export default {
  name: "homePage",
  components: {
    Contactform,
    NavBar,
    Mybutton,
    JoinForm,
    Footer
  },
  data() {
    return {
      client: true,
      noclient: false
    }
  },
  methods: {
    contact() {
      this.client = false,
          this.noclient = true
    }
  }
}
</script>
<style scoped>

.navbarfrontpage {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  min-height: 100vh;

}

.nav-bar {
  width: fit-content;
  margin: auto;
  height: fit-content;
  background-color: transparent;
  display: flex;
  justify-content: space-around;

}

.frontpage {
  width: fit-content;
  margin: auto;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  
}

.frontpage h1 {
  color: white;
  font-size: 4.8rem;
  width: fit-content;
  padding: 10rem 5% 0rem 5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 60rem;
}

.frontpage h4 {
  color: white;
  font-weight: 100;
  font-size: 1.7rem;
  padding-left: 5rem;
  
  line-height: 2rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 40rem;
}

.discover {
  padding-left: 5rem;

}

.item1 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item1.jpg");
  height: 35vw;
  width: 70%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  left: calc(50% - 35%);

}

.item2 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item2.jpg");
  height: 35vw;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  width: 70%;

  left: calc(50% - 35%);

}

.item3 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item3.jpg");
  height: 35vw;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  width: 70%;
  left: calc(50% - 35%);

}

.items {


  margin-top: 2rem;
  margin-bottom: 2rem;
}

.next {
  margin-right: 15%;
  width: 10%;
}

.prev {
  margin-left: 15%;
  width: 10%;
}

.item-content {
  display: flex;
  justify-content: center;
  margin-top: 28%;
}

.text {
  color: white;
  width: 60%;
}

.feature {
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;

}

.featureimage1 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item1.jpg");

}

.featureimage2 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item2.jpg");
}

.featureimage3 {
  background-image: linear-gradient(100deg, #1d67d691 0%, #04146e91 74%), url("../assets/item3.jpg");

}

.featureimage {
  width: 500px;
  height: 400px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.featuretext {
  padding-top: 3rem;
}

.featuretext h2 {
  font-size: 40px;
}

.featuretext p {
  font-size: 25px;
  padding: 0 2rem;
}

.contactform {
  width: fit-content;
  min-width: 550px;
  padding: 3rem 1rem;
  background-color: rgba(255, 255, 255, .15);
  height: fit-content;
  margin-right: 5rem;
}

.joinform {
  width: fit-content;
  min-width: 500px;
  background-image: linear-gradient(225deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, .35));
  background-size: 400%;
  height: fit-content;
  
  padding: 2rem;
  border-radius: 20px;
  
  margin-top: 7rem;
  margin-right: 5rem;
  animation: fadebackground 3s infinite;

}

.joinform:hover {
  animation: scaleup 0.3s;
  animation-fill-mode: forwards;
}



@keyframes scaleup {
  to {
    transform: scale(1.02);

  }

}

@keyframes fadebackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media  screen and (max-width:1600px) {
  .sidetext{
    margin-top: -5rem;
  }
  .sidetext h1{
    font-size: 45px;
    width:45rem;
  }
  .sidetext h4{
    font-size: 20px;
    width: 30rem;
  }
  .joinform{
    margin-top: -0.5rem;
  }
  .contactform{
    margin-top: -0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
}
@media screen and (max-width: 1400px) {
  .sidetext {
    display: none;
  }
  .item-content{
    margin-top: 22%;
  }
  .featuretext{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    text-align: center;
  }
  .featureimage{
    margin: auto;
  }
  .joinform{
    margin-top:1rem;
  }
}

@media screen and (max-width: 950px) {
  .sidetext {
    display: none;
  }

  .navbarfrontpage {
    height: 700px;
    min-height: 800px;
  }
 
  
  .joinform {
    min-height: 400px;
    margin: 6rem 2rem 2rem 2rem;
  }

  .item1 {
    height: 700px;
  }

  .item2 {
    height: 700px;
  }

  .item3 {
    height: 700px;
  }

  .item-content {
    flex-direction: column;
    align-items: center;
  }
  .carousel-inner{
    height: 500px;
  }

  .text {
    text-align: center;
  }

  .feature {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .featuretext {
    text-align: center;
    width: 70vw;

  }

  .featuretext p {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .joinform {
    min-width: 300px;
    margin: 1rem;
    margin-top: 3rem;
  }

  .navbarfrontpage {
    height: 700px;
    min-height: 600px;
  }

  .item-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .carousel-inner{
    height: 410px;
  }

  .text h4 {
    font-size: 20px;
    text-align: center;
  }

  .text h1 {
    text-align: center;
  }

  .featuretext h2 {
    font-size: 30px
  }

  .featuretext p {
    font-size: 15px;
    padding: 0;
  }
}
</style>

