<template>
<div class="form">

    <h1>Become a client</h1>
    
    <div class="p-fluid p-grid">
        <div class="p-field p-col-12 p-md-8">
            <div class=" form-floating">
                <input type="text" class="form-control"  placeholder="First Name" id="firstName">

                <label for="firstName" class="form-label">First Name</label>
                
            </div>
        </div>
        
        <br>
        <div class="p-field p-col-12 p-md-8">
            <div class=" form-floating">
                <input type="text" class="form-control"  placeholder="Last Name" id="lastName">

                <label for="lastName" class="form-label">Last Name</label>
                
            </div>
        </div>
        
        <br>
        <div class="p-field p-col-12 p-md-8">
            <div class=" form-floating">
                <input type="email" class="form-control"  placeholder="Email" id="Email">

                <label for="Email" class="form-label">Email</label>
                
            </div>
        </div>
    </div>
   
    <div class="btns">
      <Mybutton txtc="white" bgc="#4E9258" textcontent="Schedule a demo" link="https://sunthetics.io/pages/request-a-demo" />
      <Mybutton txtc="white" bgc="#2B65EC" textcontent="Talk to a specialist" link="https://sunthetics.io/pages/request-a-demo" />
    </div>
    
    <div class="subform">
        <h3>You are a client ?</h3>
        <Mybutton bgc="blue" txtc="white" textcontent="Access Platform" link="/dashboard"/>
    </div>
</div>
</template>

<script>
import Mybutton from "./Mybutton.vue"
export default {
  name: "Contactform",
  components: {
      Mybutton
  },
};
</script>
<style scoped>
.form{
    margin-inline-start: 10% ;
    margin-inline-end: 6rem;
    height: fit-content;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.btns{
    display: flex;
    justify-content: space-around;
}
h1{
    width: fit-content;
    margin: auto;
    color: white;
}
label{
    color:black;
    font-size: 20px;
}
.subform{
    display: flex;
    justify-content: center;
}
h3{
    margin-right: 2rem;
    padding-top: 1.5rem;
    color: white;
}
@media screen and (max-width:1400px) {
    label{
        font-size: 15px;
    }
    h1{
        font-size: 30px;
    }
    .form{
        row-gap: 20px;
    }
}
</style>
