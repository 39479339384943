<template>

    <nav class="navbar navbar-expand-lg ">
      <div class="container-fluid">
        <a href="/" class="nav-navbrand"><img class="img img-responsive navbar-brand" height="100"
                                              src="../assets/logo1.png"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="Menu"><i class="bi bi-list text-light" style="font-size:50px;"></i></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 lead slide-right">
            <li class="nav-item">
              <a class="nav-link active" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://sunthetics.io/pages/about" target="_blank">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://sunthetics.io/pages/technology" target="_blank">Technology</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://sunthetics.io/pages/team" target="_blank">Team</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://sunthetics.io/pages/highlights" target="_blank">Highlight</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://sunthetics.io/pages/contact-us" target="_blank">Contact</a>
            </li>
            
            
          </ul>
          
        </div>
      </div>
    </nav>

</template>

<script>
import Mybutton from './Mybutton.vue'
export default {
    name: "NavBar",
    methods:{
      goToDashboard(){
        this.$emit("goToDashboard", true)
      }
    },
    components:{
      Mybutton
    }
    
}
</script>
<style  scoped>

.nav-link{
  font-size: 1.5vw;
  color: white;
}

</style>
