import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
    applyPolyfills,
    defineCustomElements
  } from '@aws-amplify/ui-components/loader';
  
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
  
applyPolyfills().then(() => {
    defineCustomElements(window);
});





import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"               //core css
import "primeicons/primeicons.css"

const app = createApp(App)

app.config.isCustomElement = tag => tag.startsWith('amplify-');
library.add(faUserSecret)



app.use(router)
app.use(PrimeVue)

app.component('font-awesome-icon', FontAwesomeIcon)

app.component("InputText",InputText)
app.component("Button",Button)
app.mount('#app')
