<template>
<div class="center">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <!-- Add font awesome icons -->
  <a href="https://www.instagram.com/sunthetics_ml" target="_blank" class="fa fa-instagram fa-2x me-2" aria-hidden="true"></a>
  <a href="https://sunthetics.io/" target="_blank" class="fa fa-home fa-2x me-2 " aria-hidden="true"></a>
  <a href="https://www.linkedin.com/company/sunthetics?trk=public_profile_topcard-current-company" target="_blank"  class="fa fa-linkedin-square fa-2x"></a>
</div>
<div class="legal center">
     <p><b><u><a href="/terms"  :style="{color: colorr}">
       Standard Legal Terms</a></u></b></p>
</div>

</template>

<script>
export default {
  name: "Footer",
  components: {
  },
  props :{
    colorr : {}
  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fa {
  
  text-decoration: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  
  
}
.fa-facebook {
  display: flex;
  text-align: start;
  padding: 10px;
  justify-content: center;
}
.center {
display: flex;
justify-content: center;
}
.fa-twitter {
  display:flex;
  color: #55ACEE;
  padding: 10px;
  justify-content: center;
  
}
.fa-linkedin {
  display: flex;
  color: #007bb5;
  padding: 10px;
  justify-content: center;
  
}
.legal {
  display:flex;
  padding: 20px;
  text-align: start;
  text-decoration: none;
  
  
}
.fa:hover {
    opacity: 1;
}
</style>